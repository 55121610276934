import { track } from '../../analytics/events';

export const desktopAnalytics = () => ({
  // FORM EVENTS
  form: {
    submitted: () => track('formSubmitted', {
      formType: 'EXTENDED_MAIL_FORWARDING',
      formName: 'EXTENDED_MAIL_FORWARDING',
    }),
  },
  // CLICK EVENTS
  click: {
    back: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Back',
      },
      actionOutcome: 'INTERNALLINK',
    }),
    next: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Next',
      },
      actionOutcome: 'SUBMIT',
    }),
  },
});

export const mobileAnalytics = () => ({
  // CLICK EVENTS
  click: {
    back: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Back',
      },
      actionOutcome: 'INTERNALLINK',
    }),
    next: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Next',
      },
      actionOutcome: 'SUBMIT',
    }),
  },
});
