<template>
  <div>
    <AppLoader
      v-if="loading"
      modal
    />
    <main class="u-container">
      <header class="flex">
        <div class="lg:w-2/3">
          <h1
            id="emf-heading"
            class="font-bold"
          >
            Extend Your Mail Forwarding End Date
          </h1>
          <p class="mt-5">
            After completing a Change-of-Address, your mail will be forwarded to your new address for 12 months following the Mail Forwarding Start Date you provided.
          </p>
          <p class="mt-5">
            For your added convenience, we offer you the option of extending your Mail Forwarding End Date now for an additional cost.
          </p>
          <p class="mt-5">
            Make sure you don't forget months later: extend your Mail Forwarding End Date now for an added peace of mind.
          </p>
          <p class="mt-5">
            This is a nonrefundable product. You cannot change or cancel your Extended Mail Forwarding request.
          </p>
        </div>
      </header>
      <div class="c-hairline mt-6" />
      <form
        ref="form"
        novalidate
        @submit.prevent="validate(); analytics.form.submitted();"
      >
        <section class="my-8">
          <h2 class="font-bold text-2xl">
            Select Extension Period
          </h2>
          <div class="flex">
            <InputDropdown
              id="emf-input-select"
              ref="select"
              v-model="selectedOptionId"
              name="extension-period"
              class="mt-3 w-full lg:w-1/2"
              :has-error="errors.selectedOptionId.hasError"
            >
              <option value="">
                Select
              </option>
              <option
                v-for="extension in extensions"
                :key="extension.optionId"
                :value="extension.optionId"
              >
                {{ extension.optionPeriod }} months - ${{ extension.optionCost }}
              </option>
              <template #helper>
                {{ errors.selectedOptionId.message }}
              </template>
            </InputDropdown>
          </div>
        </section>
        <div class="c-hairline mt-6" />
        <section class="my-8">
          <p class="mt-3">
            By clicking "Next", I have read, understand and agree to the <a
              :href="`${$options.mgoUrl}/mgo/terms-of-use`"
              target="_blank"
              rel="noopener noreferrer"
              class="c-link--inline"
            >Terms and Conditions.</a>
          </p>
          <div class="flex mt-3">
            <router-link
              id="back"
              :to="{ name: 'review' }"
              class="c-btn--secondary mr-6"
              @click.native="analytics.click.back()"
            >
              Back
            </router-link>
            <button
              id="forward"
              class="c-btn"
              @click="analytics.click.next()"
            >
              Next
            </button>
          </div>
        </section>
      </form>
    </main>
  </div>
</template>

<script>
import {
  AppLoader,
  InputDropdown,
} from '@mgo/ui/lib/components';
import { desktopAnalytics } from './analytics';
import emfMixin from './mixin';
import destinationManagerMixin from '../../mixins/destinationManager';

export default {
  name: 'ExtendedMailForwarding',
  components: {
    AppLoader,
    InputDropdown,
  },
  mixins: [emfMixin, destinationManagerMixin],
  computed: {
    analytics() {
      return desktopAnalytics();
    },
  },
};
</script>
