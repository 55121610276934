import { object } from 'yup';
import {
  mapErrors,
  objectIsValid,
} from '@mgo/core/src/validations/utils';
import validators from './validations';

export default {
  data() {
    return {
      lazy: true,
      loading: true,
      coa: {},
      selectedOptionId: '',
    };
  },
  async mounted() {
    this.coa = await this.$coac.search();

    this.loading = false;
  },
  computed: {
    extensions() {
      return this.coa?.extInfo?.pricing || [];
    },
    errors() {
      return mapErrors(this, ['selectedOptionId']);
    },
    formIsValid() {
      return objectIsValid(this.errors);
    },
    rules() {
      return object().shape(validators);
    },
  },
  methods: {
    async validate() {
      if (this.loading) return;

      this.lazy = false;
      await this.$nextTick();

      if (!this.formIsValid) {
        this.loading = false;
        this.scrollToFirstError();
        return;
      }

      this.submit();
    },
    async scrollToFirstError() {
      const errorHTML = document.querySelector('.errors');
      if (errorHTML !== null) {
        setTimeout(() => {
          errorHTML.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      }
    },
    async submit() {
      this.loading = true;
      const { redirectUrl, extensionId } = await this.$coac.globalPaymentURLGet({
        returnUrl: `${window.location.origin}/mgoc/extended-mail-forwarding`,
        optionId: this.selectedOptionId,
      });

      if (!redirectUrl) {
        this.loading = false;
        this.$router.replace({ name: 'fatal-error' });
        return;
      }

      // Set extension id for commit step post Global Payment
      sessionStorage.setItem('coacext', extensionId);

      this.toPayment(redirectUrl);
    },
    toPayment(redirectUrl) {
      window.location = redirectUrl;
    },
  },
};
